import * as Sentry from '@sentry/browser';

const envMetaEl : HTMLMetaElement | null = document.querySelector("meta[name='environment']");
const dsnMetaEl : HTMLMetaElement | null = document.querySelector("meta[name='sentry']");
const environment = envMetaEl?.content || "unknown";
const dsn = dsnMetaEl?.content;

if (dsn) {
  Sentry.init({
    dsn: dsn,
    integrations: [Sentry.browserTracingIntegration()],
    environment: environment,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      // https://github.com/getsentry/sentry/issues/5267
      'SecurityError: Blocked a frame with origin "https://www.delivereasy.co.nz" from accessing'
    ],
    tracesSampleRate: 0.01,
  });

  if (window.sentryExtraUserInfo != undefined) {
    Sentry.setUser(window.sentryExtraUserInfo);
  }
} else {
  console.warn("Skipping Sentry configuration because no DSN was supplied");
}
